import { getLabelerInfo } from 'api/dataLayer';
import React, { FC, useEffect, useState } from 'react';
import Icon from '../../../components/Icons';

const UserDetails: FC<{
  labelerId?: number;
  reviewerId?: number;
  isLabeler: boolean;
}> = ({ labelerId, reviewerId, isLabeler }) => {
  let role: string | undefined;

  if (labelerId && !isLabeler) role = 'Labeler';
  if (reviewerId && isLabeler) role = 'Reviewer';

  const [userName, setUserName] = useState('');
  useEffect(() => {
    let id: number | null = null;
    if (labelerId && !isLabeler) id = labelerId;
    if (reviewerId && isLabeler) id = reviewerId;

    if (id === null) {
      setUserName('');
      return;
    }
    getLabelerInfo(id)
      .then((user) => setUserName(`${user.first_name} ${user.last_name}`))
      .catch(() => {
        setUserName((id ?? '').toString());
      });
  }, [isLabeler, labelerId, reviewerId]);

  if (!role) return null;
  return (
    <span className="d-md-inline-flex justify-content-center text-12">
      <Icon name="user" className="mr-2" />
      {`${role}: ${userName}`}
    </span>
  );
};

UserDetails.displayName = 'UserDetails';

export default UserDetails;
