import React, { useState, useEffect } from 'react';
import { Alert, Col, Row, Spinner } from 'react-bootstrap';
import { getClustersRejections } from 'api/clusters';
import moment from 'moment';
import { getUserInfo } from 'api/dataLayer';
import classNames from 'classnames';
import { ErrorIcon } from '../Icons';

function ButtonSpinner() {
  return <Spinner size="md" animation="border" role="status" />;
}

const Reviewer = ({ labelerId, reviewerId }) => {
  const [reviewerName, setReviewerName] = useState('');

  useEffect(() => {
    const id = labelerId ?? reviewerId ?? null;

    if (id === null) {
      setReviewerName('unknown');
      return;
    }
    getUserInfo(id)
      .then((user) => setReviewerName(`${user.first_name} ${user.last_name}`))
      .catch(() => {
        setReviewerName((id ?? 'unknown').toString());
      });
  }, [labelerId, reviewerId]);

  return (
    <span className="text-nowrap text-black-75">
      {reviewerId ? <>reviewer: </> : <>labeler: </>}
      {reviewerName}
    </span>
  );
};

// This is new RejectErrorComponent for new UI
function RejectInfoComponent({ id, setLastRejectComment, expanded }) {
  const [clustersRejectionsHistory, setClustersRejectionsHistory] = useState(
    {},
  );
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getComment = (element) => {
    return (
      element?.comment || 'no comment'
    );
  };

  // TODO: Move to StationControlPanel
  useEffect(() => {
    getClustersRejections(id).then(async (response) => {
      const status = await response.status;
      if (status === 200) {
        const data = await response.json();
        setClustersRejectionsHistory(data.results);
        const lastEl = data.results[0];
        const lastComment = getComment(lastEl);
        setLastRejectComment(lastComment);
        if (!lastEl) {
          setIsError(true);
        }
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    });
  }, [id, setLastRejectComment]);

  return (
    <div
      className={classNames('mt-2', 'reject-list', {
        'reject-list__opened': expanded,
      })}
    >
      {isLoading && (
        <Alert variant="danger" className="mb-0">
          <ButtonSpinner />
        </Alert>
      )}
      {!isLoading && (
        <Alert
          variant="danger"
          className="mb-0"
          style={{
            paddingLeft: 44,
            maxHeight: 116,
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          {isError ? (
            <>This cluster is rejected, but we don't have any additional info</>
          ) : (
            <div>
              {clustersRejectionsHistory.map((el, index) => (
                <Row
                  key={el.created}
                  className={classNames({ 'pt-3': !!index })}
                >
                  <Col xs={12} md={6}>
                    <span className="fw-500">
                      {index === 0 ? (
                        <>
                          <span
                            style={{
                              position: 'absolute',
                              margin: '-2px 0 0 -32px',
                            }}
                          >
                            <ErrorIcon />
                          </span>
                          <span className="fw-500">Rejected cluster:</span>
                        </>
                      ) : (
                        <>
                          <span className="bull-red" />
                          <span className="text-black-75">
                            {el.reviewer_id ? <>Reject </> : <>Comment </>}
                            {clustersRejectionsHistory.length - index}:
                          </span>
                        </>
                      )}
                    </span>{' '}
                    <span className={classNames({ 'text-black-75': !!index })}>
                      {getComment(el)}
                    </span>
                  </Col>
                  <Col xs={12} md={6} className="text-right">
                    <Reviewer
                      labelerId={el.labeler_id}
                      reviewerId={el.reviewer_id}
                    />
                    <span className="text-nowrap text-black-75">
                      {'  '}at{' '}
                      {el.created
                        ? moment(el.created).format('YYYY-MM-DD HH:mm')
                        : 'unknown'}
                    </span>
                  </Col>
                </Row>
              ))}
            </div>
          )}
        </Alert>
      )}
    </div>
  );
}

export default RejectInfoComponent;
