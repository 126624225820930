import { useEffect } from 'react';
import { getJobTypes } from '../api/jobTypes';
import { useUserContext } from '../contexts/Users';
import { JobTypeTO } from '../api/api.types';

export const JOB_PAGE_SIZE = 999;

export default function useJobTypes(
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setJobTypes: React.Dispatch<React.SetStateAction<JobTypeTO[]>>,
): void {
  const { isLabeler, setAssignJobTypes } = useUserContext();
  useEffect(() => {
    const fetchJobTypes = async () => {
      try {
        setLoading(true);

        const response = await getJobTypes({
          for_clustering: true,
          page_size: JOB_PAGE_SIZE,
        });
        const data = await response.json();
        const jobTypeList = data.results;
        if (isLabeler) {
          setAssignJobTypes();
        } else {
          setLoading(false);
        }
        setJobTypes(jobTypeList);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching job types:', error);
      }
    };
    fetchJobTypes();
  }, [isLabeler, setAssignJobTypes, setJobTypes, setLoading]);
}
