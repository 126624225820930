// TODO this code has been moved from parent folder, should be moved to tsx, separate into parts and add tests
import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Card,
  Spinner,
  Modal,
  Button,
  ListGroup,
} from 'react-bootstrap';

import {
  Link,
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import ReactSelect from 'react-select';
import {
  getAuditReportBBS,
  getAuditReportLabel,
  getAuditReportMistakeTypes,
  updateAuditReport,
} from '../../../api/auditReport';
import ListPaginator from '../../../components/Paginators/ListPaginator';
import { useUserContext } from '../../../contexts/Users';
import { getCrop } from '../../../api/featurestore';
import ModalWithSVGShape from '../../../components/Modal/ModalWithSVGShape';
import { HttpStatus } from '../../../enums';
import SingleSKUReferenceItem from '../../CropLabelingProcess/SingleSKUReferenceItem';
import {
  fetchImagesQueued,
  fetchQueued,
} from '../../../services/fetchImagesQueued';
import ProductionReportChecksFilters from './ProductionReportChecksFilters';
import ReportCheckRow from './ReportCheckRow';
import ProductionReportCheckTopPanel from './ProductionReportCheckTopPanel';

export const PRODUCTION_REPORT_SEARCH = '?is_BB_UUID_Search=true';

function ProductionReportCheck() {
  const [isLoading, setIsLoading] = useState(true);
  const [currentId, setCurrentId] = useState(null);
  const [count, setCount] = useState(null);
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [error, setError] = useState(false);
  const [listCheckCrops, setListCheckCrops] = useState(null);
  const [showSVGShapeModal, setShowSVGShapeModal] = useState(false);
  const [dimensionsModal, setDimensionsModal] = useState({});
  const [showModalError, setShowModalError] = useState(false);
  const [errorTextModal, setErrorTextModal] = useState('');
  const [mistakesList, setMistakesList] = useState([]);
  const [showModalBBS, setShowModalBBS] = useState(false);
  const [resultBBS, setResultBBS] = useState([]);
  const [infoBBS, setInfoBBS] = useState({});
  const [parameters, setParameters] = useState({});
  const [selectedLabelBBS, setSelectedLabelBBS] = useState(null);
  const cancelLoadRef = useRef(() => {});

  const { id } = useParams();
  const { state } = useLocation();
  const { isViewer } = useUserContext();

  useEffect(() => {
    const params = { for_sku_id: id };
    getAuditReportMistakeTypes(params).then(async (response) => {
      const data = await response.json();
      const newMistakesList = [];
      data.forEach((el) =>
        newMistakesList.push({ label: el.name, value: el.name }),
      );
      setMistakesList(newMistakesList);
    });
  }, [id]);

  const fetchPreReviewCrops = async (data) => {
    const apiCall = (crop) => {
      return getCrop(crop.fs_label_id, {
        photo_id: crop.photo_id,
        centroid_scale: 1.25,
        photo_as_url: true,
      });
    };

    const onImageLoaded = (response, crop) => {
      setListCheckCrops((prev) => {
        const index = prev.findIndex((el) => el.id === crop.id);
        const nextState = [...prev];
        if (index >= 0) {
          nextState[index].preReviewCrop = {
            source: response,
            photoId: crop.photo_id,
          };
          nextState[index].loadingImage = false;
        }
        return nextState;
      });
    };

    const onImageLoadFailed = (crop) => {
      setListCheckCrops((prev) => {
        const index = prev.findIndex((el) => el.id === crop.id);
        const nextState = [...prev];
        if (index >= 0) {
          nextState[index].loadingImage = false;
          nextState[index].isLoadingImageError = true;
        }
        return nextState;
      });
    };

    const getImgUrl = (el) => el.crop;

    const { cancelLoad } = fetchImagesQueued(
      data,
      apiCall,
      onImageLoaded,
      onImageLoadFailed,
      getImgUrl,
    );

    cancelLoadRef.current = cancelLoad;
  };

  const setAuditReportCheck = useCallback(
    (params) => {
      cancelLoadRef.current();
      cancelLoadRef.current = () => {};

      getAuditReportLabel(id, params).then(async (response) => {
        const status = await response.status;
        if (status === 200) {
          await response.json().then(async (data) => {
            const { results } = data;
            if (!results || results.length === 0) {
              setCount(null);
              setNext(null);
              setPrevious(null);
              setCurrentId(id);
              setError(false);
              setListCheckCrops(null);
              return;
            }
            setCount(data.count);
            setNext(data.next);
            setPrevious(data.previous);
            setCurrentId(id);
            setError(false);

            const newReportDetails = [];
            results.forEach((item) => {
              const newMistakesList = [];
              item.mistake_types.forEach((el) =>
                newMistakesList.push({ label: el, value: el }),
              );
              newReportDetails.push({
                ...item,
                mistakeValue: newMistakesList,
                mistakeSuccessMessage: false,
                mistakeErrorMessage: false,
                mistakeIsLoading: false,
                isDisabledMistakeButton: true,
                isLoadingMistake: false,

                isLoadingImageError: false,
                loadingImage: true,
              });
            });
            setListCheckCrops(newReportDetails);
            await fetchPreReviewCrops(newReportDetails);
          });
        } else {
          setError(true);
          setListCheckCrops(null);
        }
        setIsLoading(false);
      });
    },
    [id],
  );

  useEffect(() => {
    if (currentId !== id) {
      setAuditReportCheck({ page: 1, page_size: pageSize, ...parameters });
    }
  }, [id, pageSize, currentId, parameters, setAuditReportCheck]);

  const handleChangeState = (newValue, key, newId) => {
    let newState = {};
    switch (key) {
      case 'flag':
        newState = {
          flag: newValue,
          mistakeValue: [],
          mistakeSuccessMessage: false,
          mistakeErrorMessage: false,
          isDisabledMistakeButton: newValue !== 'WRONG_TAGGED',
        };
        break;
      case 'mistakeValue':
        newState = {
          mistakeValue: newValue,
          mistakeSuccessMessage: false,
          mistakeErrorMessage: false,
          isDisabledMistakeButton: Object.keys(newValue).length === 0,
        };
        break;
      case 'mistakeSuccessMessage':
        newState = {
          mistakeSuccessMessage: true,
          mistakeErrorMessage: false,
          isLoadingMistake: false,
        };
        break;
      case 'mistakeErrorMessage':
        newState = {
          mistakeSuccessMessage: false,
          mistakeErrorMessage: true,
          isLoadingMistake: false,
        };
        break;
      default:
        newState = {
          [key]: newValue,
        };
    }

    setListCheckCrops(
      listCheckCrops.map((item) => {
        if (item.id === newId) {
          return { ...item, ...newState };
        }
        return item;
      }),
    );
  };

  const loadData = (props) => {
    const newPage = props.page;
    setIsLoading(true);
    setPage(newPage);
    setAuditReportCheck({ page: newPage, page_size: pageSize, ...parameters });
  };

  const onSendMistakes = (newId) => {
    handleChangeState(true, 'isLoadingMistake', newId);
    const crop = listCheckCrops.find((el) => el.id === newId);
    const mistakes = crop.mistakeValue.map((el) => el.value);
    const params =
      mistakes.length > 0
        ? {
            flag: crop.flag,
            mistake_types: mistakes.join(','),
          }
        : {
            flag: crop.flag,
          };
    updateAuditReport(newId, params).then((res) => {
      if (res.status === HttpStatus.SUCCESS) {
        handleChangeState(true, 'mistakeSuccessMessage', newId);
      } else {
        handleChangeState(true, 'mistakeErrorMessage', newId);
      }
    });
  };

  const onSendAllMistakes = async () => {
    setListCheckCrops(
      listCheckCrops.map((item) => {
        return { ...item, isLoadingMistake: true };
      }),
    );

    const sendErrorCrop = listCheckCrops.filter(
      (el) => !el.isDisabledMistakeButton,
    );
    const cropResults = [];

    const apiCall = (crop) => {
      const mistakes = crop.mistakeValue.map((el) => el.value);
      const params =
        mistakes.length > 0
          ? {
              flag: crop.flag,
              mistake_types: mistakes.join(','),
            }
          : {
              flag: crop.flag,
            };
      return updateAuditReport(crop.id, params);
    };

    const onSuccess = (crop) => {
      const newCrop = {
        ...crop,
        mistakeSuccessMessage: true,
        mistakeErrorMessage: false,
      };
      cropResults.push(newCrop);
    };

    const onError = (crop) => {
      const newCrop = {
        ...crop,
        mistakeSuccessMessage: false,
        mistakeErrorMessage: true,
      };
      cropResults.push(newCrop);
    };

    const { result } = fetchQueued(sendErrorCrop, apiCall, onSuccess, onError);
    await result;

    setListCheckCrops(
      listCheckCrops.map((item) => {
        if (!item.isDisabledMistakeButton) {
          const newItem = cropResults.find((el) => el.id === item.id);
          return {
            ...newItem,
            isLoadingMistake: false,
            isDisabledMistakeButton: true,
          };
        }
        return { ...item, isLoadingMistake: false };
      }),
    );
  };

  const onChangePageSize = (newPagesize) => {
    setIsLoading(true);
    setPageSize(newPagesize.value);
    setPage(1);
    setAuditReportCheck({
      page: 1,
      page_size: newPagesize.value,
      ...parameters,
    });
  };

  const pageSizes = [1, 5, 10, 50, 100, 500, 1000];

  const valuePages = pageSizes.map((el) => ({ label: el, value: el }));

  const showModal = (el) => {
    setDimensionsModal({});
    getCrop(el.fs_label_id, {
      photo_id: el.photo_id,
      centroid_scale: 100,
      photo_as_url: true,
    }).then(async (response) => {
      const status = await response.status;
      if (status === 200) {
        const data = await response.json();
        const result = {
          url: data.crop,
          source_img_url: data.crop,
          x_min: data.rect.x,
          y_min: data.rect.y,
          x_max: data.rect.x + data.rect.width,
          y_max: data.rect.y + data.rect.height,
        };
        setDimensionsModal(result);
        setShowSVGShapeModal(true);
      } else if (status === 204) {
        setErrorTextModal(
          'For this crop there is no original job image available.',
        );
        setShowModalError(true);
      } else {
        setErrorTextModal('Sorry, something went wrong');
        setShowModalError(true);
      }
    });
  };

  const onClickBBS = (value) => {
    setInfoBBS({ count: 0, label: value, isLoading: true, error: false });
    setResultBBS([]);
    setShowModalBBS(true);
    getAuditReportBBS({ name_like: value, page_size: 999 }).then(
      async (response) => {
        const status = await response.status;
        if (status === 200) {
          const data = await response.json();
          const newDataBBS = data.results;
          setResultBBS(newDataBBS);
          setInfoBBS({
            label: value,
            isLoading: false,
            error: false,
          });
        } else {
          setInfoBBS({ label: value, isLoading: false, error: true });
        }
      },
    );
  };

  const filterCallback = (searchFsLabelId, searchPrediction, searchTagged) => {
    setIsLoading(true);
    const params = {};
    if (searchFsLabelId) params.fs_label_id = searchFsLabelId;
    if (searchPrediction) params.prediction = searchPrediction;
    if (searchTagged) params.tagged = searchTagged;
    setPage(1);
    setParameters(params);
    setAuditReportCheck({ page: 1, page_size: pageSize, ...params });
  };

  const isDisabledAllMistakeButton =
    listCheckCrops?.findIndex((el) => !el.isDisabledMistakeButton) < 0;
  const isLoadingAllMistakeButton =
    isLoading || !(listCheckCrops?.findIndex((el) => el.isLoadingMistake) < 0);

  const handleApproveBBS = () => {
    const newID = resultBBS.find((el) => el.name === selectedLabelBBS);
    const link = `/clusters${PRODUCTION_REPORT_SEARCH}&brandbank_uuid=${newID.id}`;
    window.open(link, '_blank');
  };

  return (
    <>
      <ModalWithSVGShape
        crop={dimensionsModal}
        handleClose={() => {
          setShowSVGShapeModal(false);
        }}
        show={showSVGShapeModal}
      />
      <Modal show={showModalError} onHide={() => setShowModalError(false)}>
        <Modal.Header closeButton> </Modal.Header>
        <Modal.Body>{errorTextModal}</Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={showModalBBS}
        onHide={() => setShowModalBBS(false)}
      >
        <Modal.Header closeButton>
          <div>
            Brand Bank search result(s) for:
            <span className="font-weight-bolder"> {infoBBS?.label}</span>
          </div>
        </Modal.Header>
        <Modal.Body>
          {infoBBS?.isLoading ? (
            <Spinner size="lg" animation="border" role="status" />
          ) : (
            <>
              {infoBBS?.error && <div>Sorry, something went wrong</div>}
              {!infoBBS?.error && resultBBS.length === 0 && (
                <Alert variant="dark">Nothing found</Alert>
              )}
              {!infoBBS?.error && resultBBS.length > 0 && (
                <div>
                  {resultBBS.map((item) => (
                    <ListGroup.Item key={item.id}>
                      <SingleSKUReferenceItem
                        name={item.name}
                        selectedLabel={selectedLabelBBS}
                        onClick={(el) => setSelectedLabelBBS(el)}
                        photos={item.photos}
                      />
                    </ListGroup.Item>
                  ))}
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={!selectedLabelBBS}
            variant="danger"
            onClick={handleApproveBBS}
            data-testid="BBSearch-modal-button"
          >
            Search
          </Button>
        </Modal.Footer>
      </Modal>
      <ProductionReportCheckTopPanel
        state={state}
        skuProgress={listCheckCrops?.[0].sku_progress}
        isViewer={isViewer}
        isLoadingAllMistakeButton={isLoadingAllMistakeButton}
        isDisabledAllMistakeButton={isDisabledAllMistakeButton}
        onSendAllMistakes={onSendAllMistakes}
      />
      <ProductionReportChecksFilters
        filterCallback={filterCallback}
        isLoadingSearch={isLoading}
      />
      {isLoading && <Spinner size="lg" animation="border" role="status" />}
      {error && (
        <div className="alert alert-danger">Sorry, something went wrong</div>
      )}
      {!isLoading && !error && listCheckCrops.length === 0 && (
        <Alert variant="dark">
          Nothing to show. Please go to{' '}
          <Link to="/audit-reports">Audit Reports</Link>{' '}
        </Alert>
      )}
      {!isLoading && !error && listCheckCrops.length !== 0 && (
        <Card className="card-сrops">
          <Card.Body>
            {listCheckCrops.map((el) => (
              <ReportCheckRow
                key={el.id}
                crop={el}
                showModal={showModal}
                onClickBBS={onClickBBS}
                handleChangeState={handleChangeState}
                mistakesList={mistakesList}
                onSendMistakes={onSendMistakes}
                isLoading={isLoading}
                isViewer={isViewer}
              />
            ))}
          </Card.Body>
          <Card.Footer>
            <div className="stats d-flex  justify-content-between flex-wrap">
              <div className="text-nowrap pr-3 text-body mt-1 mb-2 h4">
                Total: {count}
              </div>
              {count > pageSize && (
                <ListPaginator
                  total={count}
                  current={page}
                  pageSize={pageSize}
                  previous={previous}
                  next={next}
                  changePage={loadData}
                />
              )}
              <div className="pl-3 mt-n1 text-nowrap">
                Crops per page
                <ReactSelect
                  value={valuePages[pageSizes.indexOf(pageSize)]}
                  onChange={onChangePageSize}
                  isDisabled={isLoading}
                  options={valuePages}
                  placeholder=""
                  className="pl-3 d-inline-block"
                  style={{ height: 35 }}
                />
              </div>
            </div>
          </Card.Footer>
        </Card>
      )}
    </>
  );
}

ProductionReportCheck.propTypes = {
  userType: PropTypes.string,
};

ProductionReportCheck.defaultProps = {
  userType: '',
};

export default ProductionReportCheck;
