/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import { useBatchLabelingContext } from '../../../contexts/BatchLabelingProcess';
import { useStationStateContext } from '../../../contexts/StationStateContext';
import SingleCard from '../../../components/CardList/SingleCard';
import CustomSpinner from '../../../components/CustomSpinner/CustomSpinner';
import IconLinkButton from '../../../components/Buttons/IconLinkButton';
import FastLaneGPTSuggestion from './FastLaneGPTSuggestion';
import { LabelTO } from '../../../api/api.types';

const FastLaneGPT = (): React.ReactElement => {
  const { isLoadingSKU, loadBestMatch } = useBatchLabelingContext();
  const [bestMatchLocal, setBestMatchLocal] = useState<LabelTO | null>(null);
  const {
    clusterData,
    loading,
    selectedJobType,
    selectedLabel,
    setSelectedLabel,
    errorMessage,
  } = useStationStateContext();
  const [openSuggestion, setSuggestionOpen] = useState<boolean>(false);
  const disableElement = !selectedJobType || !!errorMessage;
  const [prevClusterId, setPrevClusterId] = useState(clusterData?.id);

  if (clusterData?.id !== prevClusterId) {
    setPrevClusterId(clusterData?.id);
    setBestMatchLocal(null);
    setSuggestionOpen(false);

    const prevSelectedUuid = clusterData?.brandbank_uuid;
    if (prevSelectedUuid) {
      loadBestMatch(prevSelectedUuid, 1, 'entity_id').then((result: any) => {
        setBestMatchLocal(result[0]);
        setSelectedLabel(result[0]);
      });
    }
  }
  return (
    <>
      <div className="fw-500 mb-2">Best Match:</div>
      {disableElement && (
        <Row className="label-card mx-0 p-2 label-card_disabled" />
      )}
      {selectedJobType && (loading || isLoadingSKU) ? (
        <Row className="label-card mx-0 p-2 label-card_active align-items-center justify-content-center">
          <CustomSpinner />
        </Row>
      ) : (
        !disableElement && (
          <div
            role="button"
            onClick={() => bestMatchLocal && setSelectedLabel(bestMatchLocal)}
            tabIndex={0}
            className="py-2 label-card__wrapper">
            {bestMatchLocal && !!clusterData?.id ? (
              <SingleCard
                activeCard={selectedLabel?.id === clusterData?.brandbank_uuid}
                photos={bestMatchLocal?.photos}
                title={bestMatchLocal?.name}
                text={`UUID: ${bestMatchLocal?.id}`}
              />
            ) : (
              <Row
                className="label-card  label-card_disabled mx-0 p-2 align-items-center justify-content-center"
                data-testid="label-card">
                No Best Match Found
              </Row>
            )}
          </div>
        )
      )}
      <div className="mt-3 mb-2 mx-n1">
        <IconLinkButton
          icon="chevron"
          onClick={() => setSuggestionOpen(!openSuggestion)}
          className="icon-link-button_collapse fw-500"
          classNameIcon={`icon-rotate ${openSuggestion ? 'rotated_90' : ''}`}
          disabled={disableElement}>
          Other Suggestions:
        </IconLinkButton>
      </div>
      {openSuggestion && (
        <FastLaneGPTSuggestion
          isShow={openSuggestion}
          selectedLabel={selectedLabel}
          setSelectedLabel={(label) => setSelectedLabel(label)}
        />
      )}
    </>
  );
};

export default FastLaneGPT;
