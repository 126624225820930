/* eslint-disable react/jsx-props-no-spreading */
import React, { ComponentProps, FC, Fragment } from 'react';
import { ReactComponent as PauseIcon } from '../assets/img/icons/pause.svg';
import { ReactComponent as ResumeIcon } from '../assets/img/icons/resume.svg';
import { ReactComponent as InfoIcon } from '../assets/img/icons/info.svg';
import { ReactComponent as TrashIcon } from '../assets/img/icons/trash.svg';
import { ReactComponent as TrashWIcon } from '../assets/img/icons/trash_w.svg';
import { ReactComponent as UserIcon } from '../assets/img/icons/user.svg';
import { ReactComponent as SizeSmallIcon } from '../assets/img/icons/size_small.svg';
import { ReactComponent as SizeMediumIcon } from '../assets/img/icons/size_medium.svg';
import { ReactComponent as SizeLargeIcon } from '../assets/img/icons/size_large.svg';
import { ReactComponent as CheckIcon } from '../assets/img/icons/check.svg';
import { ReactComponent as CheckIcon2 } from '../assets/img/icons/checkStroke.svg';
import { ReactComponent as CheckSelectedIcon } from '../assets/img/icons/check_selected.svg';
import { ReactComponent as ZoomIcon } from '../assets/img/icons/zoom.svg';
import { ReactComponent as ShelfIcon } from '../assets/img/icons/shelf.svg';
import { ReactComponent as NewClusterIcon } from '../assets/img/icons/new_cluster.svg';
import { ReactComponent as ApplyIcon } from '../assets/img/icons/apply.svg';
import { ReactComponent as MarkXIcon } from '../assets/img/icons/mark_x.svg';
import { ReactComponent as MarkedForMoveIcon } from '../assets/img/icons/marked_for_move.svg';
import { ReactComponent as MarkedForRemoveIcon } from '../assets/img/icons/marked_for_remove.svg';
import { ReactComponent as EmptyFolderIcon } from '../assets/img/icons/empty_folder.svg';
import { ReactComponent as PrevIcon } from '../assets/img/icons/prev.svg';
import { ReactComponent as NextIcon } from '../assets/img/icons/next.svg';
import { ReactComponent as GoBackIcon } from '../assets/img/icons/back.svg';
import { ReactComponent as ErrorIcon } from '../assets/img/icons/error.svg';
import { ReactComponent as FlagIcon } from '../assets/img/icons/flag.svg';
import { ReactComponent as FlagRemoveIcon } from '../assets/img/icons/flag_remove.svg';
import { ReactComponent as FlagMoveIcon } from '../assets/img/icons/flag_move.svg';
import { ReactComponent as FlagTrashIcon } from '../assets/img/icons/flag_trash.svg';
import { ReactComponent as ForbiddenIcon } from '../assets/img/icons/forbidden.svg';
import { ReactComponent as SpinnerIcon } from '../assets/img/icons/spinner.svg';
import { ReactComponent as ChevronIcon } from '../assets/img/icons/chevron.svg';
import { ReactComponent as SearchIcon } from '../assets/img/icons/search.svg';
import { ReactComponent as CrossIcon } from '../assets/img/icons/cross.svg';
import { ReactComponent as SearchUserIcon } from '../assets/img/icons/search_user.svg';
import { ReactComponent as RefreshIcon } from '../assets/img/icons/refresh.svg';
import { ReactComponent as LogoFormInverseIcon } from '../assets/img/icons/logo-FORM-inverse.svg';

type SVGIcon = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string;
  }
>;

export type IconName =
  | 'pause'
  | 'resume'
  | 'info'
  | 'trash'
  | 'trashW'
  | 'user'
  | 'sizeSmall'
  | 'sizeMedium'
  | 'sizeLarge'
  | 'check'
  | 'checkIcon2' // TODO: Rename and implement universal stroke processing. Perhaps like in worldapp-ui.
  | 'checkSelected'
  | 'zoom'
  | 'shelf'
  | 'newCluster'
  | 'apply'
  | 'markX'
  | 'markArrow'
  | 'markTrash'
  | 'error'
  | 'emptyFolder'
  | 'flag'
  | 'flagRemove'
  | 'flagMove'
  | 'flagTrash'
  | 'forbidden'
  | 'spinner'
  | 'chevron'
  | 'search'
  | 'cross'
  | 'search_user'
  | 'refresh'
  | 'logo_form_inverse';

export interface IconProps extends ComponentProps<typeof PauseIcon> {
  className?: string;
  name: IconName;
}

export const IconsMap: Record<IconName, SVGIcon> = {
  pause: PauseIcon,
  resume: ResumeIcon,
  info: InfoIcon,
  trash: TrashIcon,
  trashW: TrashWIcon, // Add color and size params instead
  user: UserIcon,
  sizeSmall: SizeSmallIcon,
  sizeMedium: SizeMediumIcon,
  sizeLarge: SizeLargeIcon,
  check: CheckIcon,
  checkIcon2: CheckIcon2,
  checkSelected: CheckSelectedIcon,
  zoom: ZoomIcon,
  shelf: ShelfIcon,
  newCluster: NewClusterIcon,
  apply: ApplyIcon,
  markX: MarkXIcon,
  markArrow: MarkedForMoveIcon,
  markTrash: MarkedForRemoveIcon,
  emptyFolder: EmptyFolderIcon,
  error: ErrorIcon,
  flag: FlagIcon,
  flagRemove: FlagRemoveIcon,
  flagMove: FlagMoveIcon,
  flagTrash: FlagTrashIcon,
  forbidden: ForbiddenIcon,
  spinner: SpinnerIcon,
  chevron: ChevronIcon,
  search: SearchIcon,
  cross: CrossIcon,
  search_user: SearchUserIcon,
  refresh: RefreshIcon,
  logo_form_inverse: LogoFormInverseIcon,
};

const Icon: FC<IconProps> = (props) => {
  const { name, ...restProps } = props;

  const IconComponent = IconsMap[name] || Fragment;

  return <IconComponent {...restProps} />;
};

export {
  SizeSmallIcon,
  SizeMediumIcon,
  SizeLargeIcon,
  CheckIcon,
  CheckIcon2,
  CheckSelectedIcon,
  ZoomIcon,
  ShelfIcon,
  NewClusterIcon,
  ApplyIcon,
  MarkXIcon,
  MarkedForMoveIcon,
  MarkedForRemoveIcon,
  EmptyFolderIcon,
  PrevIcon,
  NextIcon,
  GoBackIcon,
  ErrorIcon,
  SpinnerIcon,
  ChevronIcon,
  SearchIcon,
  CrossIcon,
  SearchUserIcon,
  RefreshIcon,
};

export default Icon;
