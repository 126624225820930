import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useStationStateContext } from '../../../contexts/StationStateContext';
import ConfirmationModal from '../../../modals/ConfirmationModal';
import { assignAsReviewer } from '../../../api/clusters';
import { checkResponse } from '../../../api/dataLayer';

const FastLaneActionButtons = (): React.ReactElement => {
  const {
    clusterData,
    clusters,
    loading,
    setErrorMessage,
    refreshData,
    selectedLabel,
  } = useStationStateContext();
  const [modalShow, setModalShow] = useState(false);
  const [actionType, setActionType] = useState<string | null>(null);
  const [isBusy, setIsBusy] = useState(false);
  const disableButton = !clusterData || (!!clusterData && clusters.length > 1);

  useEffect(() => {
    setIsBusy(loading);
  }, [loading]);

  const handleDangerousAction = (dangerousActionType: string) => {
    setActionType(dangerousActionType);
    setModalShow(true);
  };

  const handleAction = async (aActionType?: string) => {
    const params = { fix: true };
    let reviewerParams;
    switch (aActionType || actionType) {
      case 'unknowProduct':
        reviewerParams = clusterData?.brandbank_uuid
          ? { ...params }
          : undefined;
        await assignAsReviewer(clusterData?.id, { ...reviewerParams })
          .then((response) => checkResponse(response, () => refreshData()))
          .catch((error) => setErrorMessage(error.message));
        break;
      case 'assignSelected':
        reviewerParams =
          selectedLabel?.id === clusterData?.brandbank_uuid
            ? { brandbank_uuid: selectedLabel?.id }
            : { brandbank_uuid: selectedLabel?.id, ...params };
        await assignAsReviewer(clusterData?.id, { ...reviewerParams })
          .then((response) => checkResponse(response, () => refreshData()))
          .catch((error) => setErrorMessage(error.message));

        break;
      default:
        break;
    }

    setActionType(null);
  };

  return (
    <>
      <Button
        variant="outline-secondary"
        disabled={isBusy || disableButton}
        onClick={() => {
          handleDangerousAction('unknowProduct');
        }}>
        Set as unknown product
      </Button>
      <Button
        variant="fill"
        disabled={!selectedLabel?.id || isBusy || disableButton}
        onClick={() => handleAction('assignSelected')}>
        Assign Label
      </Button>
      <ConfirmationModal
        showModal={modalShow}
        disabled={isBusy}
        modalAction={(value) => {
          if (value) handleAction();
          setModalShow(false);
        }}>
        <h3>Confirm your action</h3>
        <p>Do you really want to do this?</p>
      </ConfirmationModal>
    </>
  );
};
FastLaneActionButtons.displayName = 'FastLaneActionButtons';

export default FastLaneActionButtons;
