import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, NavLink, useHistory } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import dashboardRoutes from 'routers/dashboardRoutes';
import { useUserContext } from 'contexts/Users';
import DialogModal from 'components/Modal/Modal';
import Icon from "../Icons";

function Sidebar({ color, image }) {
  const { isAuthenticated, onLogout, userPermission, accessToStation, isClusteringLabeler } =
    useUserContext();

  const location = useLocation();
  const activeRoute = (routeName) => (location.pathname.indexOf(routeName) > -1 ? 'active' : '');

  const [showModal, setShowModal] = useState(false);

  const history = useHistory();

  const handleNavLinkClick = (item) => {
    const targetPath = isClusteringLabeler(item) && accessToStation ? accessToStation : item.path;
    return targetPath;
  };

  const shouldShow = (item) => {
    const show = item.access.includes(userPermission) && isAuthenticated === item.private;
    return show;
  };

  const shouldShowModal = (item) => {
    if (isClusteringLabeler(item) && !accessToStation) {
      setShowModal(true);
    }
  };

  const handleGoBack = () => {
    history.goBack();
    setShowModal(false);
  };

  return (
    <div className="sidebar" data-image={image} data-color={color}>
      <div className="sidebar-background" />
      <div className="sidebar-wrapper">
        <div className="logo d-flex flex-column align-items-center justify-content-center py-3">
          <Icon name="logo_form_inverse" />
          <p className="simple-text mt-2">
            Labeling
          </p>
        </div>
        <Nav>
          {dashboardRoutes.map((item) => {
            if (shouldShow(item))
              return (
                <li className={activeRoute(item.path)} key={item.name}>
                  <NavLink
                    to={() => handleNavLinkClick(item)}
                    className="nav-link pr-2 text-nowrap"
                    activeClassName="active"
                    onClick={() => shouldShowModal(item)}
                  >
                    <i className={item.icon} />
                    <p>{item.name}</p>
                  </NavLink>
                </li>
              );
            return null;
          })}
          {isAuthenticated && (
            <li>
              <button type="button" className="nav-link btn" onClick={onLogout}>
                <i className="nc-icon nc-button-power" />
                <p>Logout User</p>
              </button>
            </li>
          )}
        </Nav>
        <DialogModal
          show={showModal}
          approve="go back"
          handleApprove={() => handleGoBack()}
          title="You are not assigned to any station. Please contact your Manager"
        />
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  color: PropTypes.string,
  image: PropTypes.string
};

Sidebar.defaultProps = {
  color: 'shelfwise',
  image: undefined
};

export default Sidebar;
